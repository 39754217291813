<template>
    <div class="container center-item-vertical">
        <vCard :col="6"
               :header="'Login - Intranet V3'">
            <form @submit.prevent="validateForm">
                <v-input v-model="form.username"
                         :show-errors="showErrors"
                         :label="`E-Mailadres`"/>
                <v-input v-model="form.password"
                         :show-errors="showErrors"
                         :label="`Wachtwoord`"/>
                <p class="error__content" v-if="incorrect">Gebruikersnaam en of wachtwoord is incorrect</p>
                <input type="submit" class="btn btn-primary" value="Inloggen" :disabled="false">
            </form>
        </vCard>
    </div>
</template>

<script>
import {email, minLength, required} from '@/utils/validator'

export default {
    name: 'Login',
    title: 'Login',
    data() {
        return {
            incorrect: false,
            showErrors: false,
            form: {
                username: {
                    validations: {
                        email,
                        required,
                        minLength: minLength(8)
                    }
                },
                password: {
                    type: 'password',
                    validations: {
                        required,
                        minLength: minLength(8)
                    }
                }
            },
        }
    },
    methods: {
        validateForm() {
            this.$v.validate(this.form, () => {
                this.login()
            }, () => {
                this.showErrors = true
            })
        },
        async login() {
            let {username, password} = this.form
            let data = await this.$store.dispatch('user/login', {
                username: username.value,
                password: password.value
            })
            console.log(data)
            if (data) {
                this.$router.push({name: 'home'})
            } else {
                this.incorrect = true
            }
        }
    }
}
</script>

<style>
label,
input {
    width: 100%;
}

body {
    background-image: url(../assets/images/background.jpg);
    background-size: cover;
}

.center-item-vertical {
    height: 100vh;
    display: flex;
    align-items: center;
}

.center-item-vertical > div {
    margin: 0 auto;
}

.i-error {
    color: red;
}
</style>
